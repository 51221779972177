import React, { useRef, useState } from "react"
import PcpItem from "../PcpItem"
import WebpImage from "../WebpImage"
import NewButton from "../NewButton"
import { Link } from "gatsby"
import avgProducts from "../../services/avg-products.json"
import "./style.scss"
import Slider from "react-slick"
import SliderPagination from "../SliderPagination"

const ContactsSection = ({isHomePage}) => {
  let products = []
  avgProducts.forEach((product) => {
    product.options.forEach((option, optionIndex) => {
      const title = option?.title?.replace(/6pk|12pk|24pk|30pk|90pk/, '')?.trim()
      const isValid = [
        'brand-acuvue-moist-astigmatism',
        'brand-acuvue-moist-astigmatism-90',
        'acuvue-oasys-1-day-hydraluxe-astig-30',
        'acuvue-oasys-1-day-hydraluxe-astig-90',
      ].includes(option.value)
      if (isValid) {
        products.push({
          feature: null,
          originalPrice: `$${option.price}`,
          price: `$${option.sale_price}`,
          product: product.value,
          title: title,
          image: option.image,
          hoverImage: option.hoverImage,
          type: 'avg',
          packSize: option.quantity,
          optionIndex
        })
      }
    })
  })
  return (
    <div className="contacts-section">
      <div className="contacts-grid">
        <div>
          <div className="title">{isHomePage ? "Looking for your go-to brand?" : "Looking for lenses for astigmatism?"}</div>
          <div className="subtitle">get 35% off your first order</div>
          <div className="description">
            We now offer a selection of contact lenses from popular brands like Acuvue and Biotrue,
            including toric lenses for astigmatism. Get your favorite bestsellers with all the personalized
            benefits of a Hubble subscription at affordable prices.
          </div>
          <div className="button-container">
            <NewButton label="Shop Contacts for Astigmatism" to={'/contact-lenses/torics/'} />
          </div>
        </div>
        <div className="text-center img-container">
          <WebpImage className="acuvue-img" fileName={'PCP/AVG-Product-Stack.png'} alt={'acuvue images'} />
        </div>
      </div>
      <div className="products-grid desktop">
        {products.map((product) => {
          return(
            <PcpItem {...product} />
          )
        })}
      </div>
      <div className="products-grid mobile">
        <ProductSlider>
          {products.map((product) => {
            return(
              <PcpItem {...product} isSlider />
            )
          })}
        </ProductSlider>
      </div>
      <div className="shop-all-link">
        <Link to={'/contact-lenses/torics'}>
          Shop All
        </Link>
      </div>
    </div>
  )
}

export default ContactsSection


const ProductSlider = React.forwardRef((props, ref, ) => {
  const [currentPage, setCurrentPage] = useState(1)
  const _ref = useRef(ref)

  const settings = {
    speed: 1000,
    draggable: false,
    arrows: false,
    infinite: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const beforeChange = (prev, next) => {
    setCurrentPage(next + 1)
    if (typeof props.beforeChange === 'function') {
      props.beforeChange(prev, next)
    }
  }

  return ( <div className="products-slider">
    <Slider ref={_ref} {...settings} {...props} beforeChange={beforeChange} >
      {props.children}
    </Slider>

    { (_ref) ? <SliderPagination
        sliderRef={_ref}
        currentPage={currentPage}
        allPages={props.children?.length}
        color={"purple"}
      />
    : null }
</div>)})

